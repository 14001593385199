<template>
  <resource-search v-model="value" :can-have-new-item="canHaveNewItem" :disabled="Boolean(orderItemDeletedAt)"
                   :error-feed="errorFeed" :error-key="errorKey" :fetch-params="extraFetchParams"
                   :show-delete="showDelete" :wait-for-input="waitForInput" module="products"
                   @delete="() => this.$emit('delete')" @input="inputEmit">
    <template #popover-list-new="{ result }">
      <p class="font-weight-bold mb-0">{{ result }}</p>
    </template>

    <template #popover-list="{ result }">
      <p class="font-weight-bold mb-0">{{ result.name }}</p>
      <!-- <p>{{ result.measurement_type }}</p> -->
      <p class="text-muted mb-0">
        {{ result.range }}
      </p>
      <p class="text-muted mb-0">
        <small>£{{ result.sale_price }}</small>
      </p>
    </template>

    <template #selected="{ selected }">
      <b-row>
        <b-col>
          <p class="font-weight-bold mb-0">
            {{ selected.name }}
            <b-badge variant="primary">£{{ useDisplayPrice ? displayPrice : selected.price }}</b-badge>
          </p>
          <p class="font-weight-light mb-0">
            {{ selected.range }}
          </p>
          <p class="text-muted">{{ selected.measurement_type }}</p>
          <p v-if="orderItemDeletedAt" class="mb-0"><small class="text-danger">Order Item Deleted</small></p>
          <!-- <small class="text-muted" v-if="displayAdditionalDetails">{{ selected.category.name }}</small> -->
        </b-col>
      </b-row>
    </template>

    <template v-if="!orderItemDeletedAt" #selected-form-area>
      <slot name="form-area"/>
    </template>

    <template v-if="!orderItemDeletedAt" #selected-inline-form-area>
      <slot name="inline-form-area"/>
    </template>
  </resource-search>
</template>

<script>
import ResourceSearch from "./ResourceSearch.vue"

export default {
  components: {ResourceSearch},
  props: {
    orderItemDeletedAt: {
      default: false
    },
    useDisplayPrice: {
      type: Boolean,
      default: false
    },
    displayPrice: {
      type: [Number, String]
    },
    displayAdditionalDetails: {
      type: Boolean,
      default: true
    },
    showDelete: {
      type: Boolean,
      default: false
    },
    waitForInput: {
      type: Boolean,
      default: true
    },
    canHaveNewItem: {
      type: Boolean,
      default: false
    },
    errorFeed: Object,
    errorKey: String,
    type: String,
    value: Object
  },
  computed: {
    extraFetchParams() {
      if (this.type) {
        return {type: this.type}
      }

      return {}
    }
  },
  methods: {
    inputEmit(id) {
      // This means the product is a new product
      if (!id.id) {
        id.measurement_types_id = null
      }
      this.$emit('input', id);
    }
  }
}
</script>

<style>
</style>
