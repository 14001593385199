t
<template>
  <b-container>
    <b-form @submit.prevent="submit">
      <b-row class="mb-3">
        <b-col cols="6">
          <h1 class="font-weight-bold text-primary">Edit Supply Order</h1>
        </b-col>
      </b-row>
      <b-row v-if="isLoading">
        <b-col cols="12">
          <p class="text-center">
            <b-spinner variant="secondary"></b-spinner>
          </p>
        </b-col>
      </b-row>
      <b-row v-if="!isLoading">
        <b-col cols="12">
          <b-row>
            <b-col cols="12">
              <b-form-group :invalid-feedback="validationInvalidFeedback(errors.errors, 'name')" label="Name">
                <b-form-input v-model="supplierOrder.name" :disabled="isSaving"
                              :state="validationState(errors.errors, 'name')"/>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row class="mb-4">
            <b-col cols="12">
              <b-card>
                <b-row>
                  <b-col class="d-flex flex-row" cols="12" md="6" role="button">
                    <div class="d-flex flex-column flex-fill ml-4">
                      <p class="h4 font-weight-bold mb-0">Order Items</p>
                    </div>
                  </b-col>
                </b-row>
                <b-row class="mt-4">
                  <b-col v-if="!supplierOrder.supply_order_items.length" class="text-center" cols="12">
                    <b-card class="py-3 text-center">
                      <p class="mb-4">
                        <fa-icon class="display-1 text-dark" icon="times"></fa-icon>
                      </p>
                      <p class="font-weight-normal h5 mb-0 text-dark"> There are no supply order items. </p>
                    </b-card>
                  </b-col>
                  <b-col v-if="supplierOrder.supply_order_items.length" cols="12">
                    <hr class="mt-0"/>
                    <div v-for="(item, ind) in supplierOrder.supply_order_items" :key="ind">
                      <product-search
                        v-if="item.product_id || (item.product_id == null && item.name == null)"
                        v-show="!item.deleted_at"
                        v-model="item.product"
                        :display-price="item.sale_price"
                        :error-feed="errors.errors"
                        :error-key="'order_items.' + ind + '.product_id'"
                        :order-item-deleted-at="item.deleted_at"
                        :use-display-price="true"
                        can-have-new-item
                        class="mb-3"
                        show-delete
                        type="order_items"
                        @delete="rmItem(ind)"
                        @input="itemUpdatedDbItem(ind)">
                        <template #form-area>
                          <b-row v-if="item.product.measurement_types_id == 1">
                            <b-col cols="12">
                              <b-input-group
                                :invalid-feedback="validationInvalidFeedback(errors.errors,'product_variation_id')"
                                class="mt-4" prepend="Variation">
                                <b-form-select
                                  v-model="item.product_variation_id"
                                  :disabled="isSaving"
                                  :state="validationState(errors.errors,'order_items.' +ind +'.product_variation_id')"
                                  @change="productVariationUpdated(ind)">
                                  <b-form-select-option :value="null">Please select a variation</b-form-select-option>
                                  <b-form-select-option v-for="variation in item.product.variations"
                                                        :key="`variation${ind}-${variation.id}`" :value="variation.id">
                                    {{ variation.name }} - Width: {{ variation.width }}
                                  </b-form-select-option>
                                </b-form-select>
                              </b-input-group>
                            </b-col>

                            <b-col cols="12">
                              <b-input-group
                                :invalid-feedback="validationInvalidFeedback(errors.errors,'supply_order_items.' + ind + '.quantity')"
                                class="mt-4" prepend="Quantity">
                                <template #append>
                                  <b-input-group-text>m<sup>2</sup></b-input-group-text>
                                </template>
                                <b-form-input v-model="item.quantity" :disabled="isSaving"
                                              :state="validationState(errors.errors,'supply_order_items.' + ind + '.quantity')"></b-form-input>
                              </b-input-group>
                            </b-col>

                            <b-col cols="12" md="6">
                              <b-input-group
                                :invalid-feedback="validationInvalidFeedback(errors.errors,'supply_order_items.' + ind + '.length')"
                                class="mt-4" prepend="Length">
                                <template #append>
                                  <b-input-group-text>m<sup>2</sup></b-input-group-text>
                                </template>
                                <b-form-input v-model="item.length" :disabled="isSaving"
                                              :state="validationState(errors.errors,'supply_order_items.' + ind + '.length')"
                                              @input="calculateSquareMeters(ind)"></b-form-input>
                              </b-input-group>
                            </b-col>

                            <b-col cols="12" md="6">
                              <b-input-group
                                :invalid-feedback="validationInvalidFeedback(errors.errors,'supply_order_items.' + ind + '.status')"
                                class="mt-4" prepend="Status">
                                <b-form-select v-model="item.status" :options="options"
                                               :state="validationState(errors.errors,'supply_order_items.' + ind + '.status')">
                                </b-form-select>
                              </b-input-group>
                            </b-col>

                            <b-col cols="12" md="6">
                              <b-input-group
                                :invalid-feedback="validationInvalidFeedback(errors.errors,'supply_order_items.' + ind + '.date')"
                                class="mt-4" prepend="Date">
                                <b-form-datepicker
                                  v-model="item.date"
                                  :date-format-options="{
                                    year: 'numeric',
                                    month: 'numeric',
                                    day: 'numeric'
                                  }"
                                  :state="validationState(errors.errors,'supply_order_items.' + ind + '.date')"
                                  :value="item.date"
                                  reset-button
                                  size="sm"/>
                              </b-input-group>
                            </b-col>

                            <b-col cols="12 mt-3">
                              <b-card>
                                <b-row>
                                  <b-col class="d-flex flex-row" cols="12" role="button"
                                         @click.prevent="openLot = openLot == ind ? null : ind">
                                    <b-icon :icon="openLot != ind ? 'chevron-right' : 'chevron-down'"
                                            class="align-self-center cursor-pointer text-muted"
                                            font-scale="1.5"></b-icon>
                                    <div class="d-flex flex-column justify-content-center flex-fill ml-4">
                                      <p class="h4 font-weight-bold mb-0"> Supply Order Item Lots</p>
                                    </div>
                                  </b-col>
                                  <b-col v-if="openLot == ind" class="d-flex flex-column justify-content-end mb-0"
                                         cols="12">
                                    <div>
                                      <hr/>
                                    </div>
                                    <b-table-simple>
                                      <b-thead>
                                        <b-tr>
                                          <b-th>Lot Number</b-th>
                                          <b-th>Lot Length</b-th>
                                          <b-th>Action</b-th>
                                        </b-tr>
                                      </b-thead>
                                      <b-tbody>
                                        <b-tr v-for="(lot, lotInd) in item.lots" :key="`${ind}-lot-${lotInd}`">
                                          <b-td>#{{ lot.id ? lot.id : "" }}</b-td>
                                          <b-td>
                                            <b-form-input v-model="lot.length" placeholder="Lot Length"></b-form-input>
                                          </b-td>
                                          <b-td>
                                            <b-button variant="danger">
                                              <fa-icon icon="times"/>
                                            </b-button>
                                          </b-td>
                                        </b-tr>
                                      </b-tbody>
                                    </b-table-simple>

                                    <b-button variant="primary" @click="addLot(ind)">Add Lot</b-button>
                                  </b-col>
                                </b-row>
                              </b-card>
                            </b-col>
                          </b-row>

                          <b-row v-else>
                            <b-col cols="12">
                              <b-input-group
                                :invalid-feedback="validationInvalidFeedback(errors.errors,'product_variation_id')"
                                class="mt-4" prepend="Variation">
                                <b-form-select v-model="item.product_variation_id" :disabled="isSaving"
                                               :state="validationState(errors.errors,'order_items.' + ind + '.product_variation_id')"
                                               @change="productVariationUpdated(ind)">
                                  <b-form-select-option :value="null">Please select a variation</b-form-select-option>
                                  <b-form-select-option v-for="variation in item.product.variations"
                                                        :key="`variation${ind}-${variation.id}`" :value="variation.id">
                                    {{ variation.name }} - Width:
                                    {{ variation.width }}
                                  </b-form-select-option>
                                </b-form-select>
                              </b-input-group>
                            </b-col>

                            <b-col cols="12">
                              <b-input-group
                                :invalid-feedback="validationInvalidFeedback(errors.errors,'supply_order_items.' + ind + '.quantity')"
                                class="mt-4" prepend="Quantity">
                                <template #append>
                                  <b-input-group-text>Packs</b-input-group-text>
                                </template>
                                <b-form-input v-model="item.quantity" :disabled="isSaving"
                                              :state="validationState(errors.errors,'supply_order_items.' + ind + '.quantity')">
                                </b-form-input>
                              </b-input-group>
                            </b-col>

                            <b-col cols="12" md="6">
                              <b-input-group
                                :invalid-feedback="validationInvalidFeedback(errors.errors,'supply_order_items.' + ind + '.status')"
                                class="mt-4" prepend="Status">
                                <b-form-select
                                  v-model="item.status"
                                  :options="options"
                                  :state="validationState(errors.errors,'supply_order_items.' + ind + '.status')">
                                </b-form-select>
                              </b-input-group>
                            </b-col>

                            <b-col cols="12" md="6">
                              <b-input-group
                                :invalid-feedback="validationInvalidFeedback(errors.errors,'supply_order_items.' + ind + '.date')"
                                class="mt-4" prepend="Date">
                                <b-form-datepicker
                                  v-model="item.date"
                                  :date-format-options="{
                                    year: 'numeric',
                                    month: 'numeric',
                                    day: 'numeric'
                                  }"
                                  :state="validationState(errors.errors,'supply_order_items.' + ind + '.date')"
                                  :value="supplierOrder.supply_order_items[ind].date"
                                  reset-button
                                  size="sm"
                                />
                              </b-input-group>
                            </b-col>
                          </b-row>
                        </template>
                      </product-search>

                      <!-- None DB Item -->
                      <b-card v-if="item.product_id == null && item.name" v-show="!item.deleted_at" class="mb-3">
                        <!-- Product Name -->
                        <b-row>
                          <b-col cols="12">
                            <b-input-group
                              :invalid-feedback="validationInvalidFeedback(errors.errors,'supply_order_items.' + ind + '.name')"
                              prepend="Product">
                              <b-form-input v-model="item.name" readonly/>
                            </b-input-group>
                          </b-col>
                        </b-row>

                        <!-- Measurement Type Selector -->
                        <b-row>
                          <b-col cols="12">
                            <b-form-group
                              :invalid-feedback="validationInvalidFeedback(errors.errors,'supply_order_items.' + ind + '.measurement_types_id')"
                              class="mt-4" label="Measurement Type">
                              <b-form-select v-model="item.measurement_types_id" :disabled="isSaving"
                                             :state="validationState(errors.errors,'supply_order_items.' + ind + '.measurement_types_id')">
                                <b-form-select-option :value="null">Please select a measurement type
                                </b-form-select-option>
                                <b-form-select-option value="1">Square Meters</b-form-select-option>
                                <b-form-select-option value="2">Packs</b-form-select-option>
                              </b-form-select>
                            </b-form-group>
                          </b-col>
                        </b-row>

                        <b-row v-if="item.measurement_types_id == 1">
                          <b-col cols="12">
                            <b-input-group
                              :invalid-feedback="validationInvalidFeedback(errors.errors,'supply_order_items.' + ind + '.quantity')"
                              class="mt-4" prepend="Quantity">
                              <template #append>
                                <b-input-group-text>m<sup>2</sup></b-input-group-text>
                              </template>
                              <b-form-input
                                v-model="item.quantity"
                                :disabled="isSaving"
                                :state="
                                  validationState(
                                    errors.errors,
                                    'supply_order_items.' + ind + '.quantity'
                                  )
                                "
                              ></b-form-input>
                            </b-input-group>
                          </b-col>

                          <b-col cols="12" md="6">
                            <b-input-group
                              :invalid-feedback="
                                validationInvalidFeedback(
                                  errors.errors,
                                  'supply_order_items.' + ind + '.width'
                                )
                              "
                              class="mt-4"
                              prepend="Width"
                            >
                              <template #append>
                                <b-input-group-text
                                >m<sup>2</sup></b-input-group-text
                                >
                              </template>
                              <b-form-input
                                v-model="item.width"
                                :disabled="isSaving"
                                :state="
                                  validationState(
                                    errors.errors,
                                    'supply_order_items.' + ind + '.width'
                                  )
                                "
                                @input="calculateSquareMeters(ind)"
                              ></b-form-input>
                            </b-input-group>
                          </b-col>

                          <b-col cols="12" md="6">
                            <b-input-group
                              :invalid-feedback="
                                validationInvalidFeedback(
                                  errors.errors,
                                  'supply_order_items.' + ind + '.length'
                                )
                              "
                              class="mt-4"
                              prepend="Length"
                            >
                              <template #append>
                                <b-input-group-text
                                >m<sup>2</sup></b-input-group-text
                                >
                              </template>
                              <b-form-input
                                v-model="item.length"
                                :disabled="isSaving"
                                :state="
                                  validationState(
                                    errors.errors,
                                    'supply_order_items.' + ind + '.length'
                                  )
                                "
                                @input="calculateSquareMeters(ind)"
                              ></b-form-input>
                            </b-input-group>
                          </b-col>

                          <b-col cols="12" md="6">
                            <b-input-group
                              :invalid-feedback="
                                validationInvalidFeedback(
                                  errors.errors,
                                  'color'
                                )
                              "
                              class="mt-4"
                              prepend="Colour"
                            >
                              <b-form-input
                                v-model="item.color"
                                :disabled="isSaving"
                                :state="
                                  validationState(
                                    errors.errors,
                                    'supply_order_items.' + ind + '.color'
                                  )
                                "
                                placeholder="Product Color"
                              />
                            </b-input-group>
                          </b-col>

                          <b-col cols="12" md="6">
                            <b-input-group
                              :invalid-feedback="
                                validationInvalidFeedback(
                                  errors.errors,
                                  'design'
                                )
                              "
                              class="mt-4"
                              prepend="Design"
                            >
                              <b-form-input
                                v-model="item.design"
                                :disabled="isSaving"
                                :state="
                                  validationState(
                                    errors.errors,
                                    'supply_order_items.' + ind + '.design'
                                  )
                                "
                                placeholder="Product Design"
                              />
                            </b-input-group>
                          </b-col>

                          <b-col cols="12" md="6">
                            <b-input-group
                              :invalid-feedback="
                                validationInvalidFeedback(
                                  errors.errors,
                                  'supply_order_items.' + ind + '.status'
                                )
                              "
                              class="mt-4"
                              prepend="Status"
                            >
                              <b-form-select
                                v-model="item.status"
                                :options="options"
                                :state="
                                  validationState(
                                    errors.errors,
                                    'supply_order_items.' + ind + '.status'
                                  )
                                "
                              >
                              </b-form-select>
                            </b-input-group>
                          </b-col>

                          <b-col cols="12" md="6">
                            <b-input-group
                              :invalid-feedback="
                                validationInvalidFeedback(
                                  errors.errors,
                                  'supply_order_items.' + ind + '.date'
                                )
                              "
                              class="mt-4"
                              prepend="Date"
                            >
                              <b-form-datepicker
                                v-model="item.date"
                                :date-format-options="{
                                  year: 'numeric',
                                  month: 'numeric',
                                  day: 'numeric'
                                }"
                                :state="
                                  validationState(
                                    errors.errors,
                                    'supply_order_items.' + ind + '.date'
                                  )
                                "
                                :value="
                                  supplierOrder.supply_order_items[ind].date
                                "
                                reset-button
                                size="sm"
                              />
                            </b-input-group>
                          </b-col>
                        </b-row>

                        <b-row v-else-if="item.measurement_types_id == 2">
                          <b-col cols="12">
                            <b-input-group
                              :invalid-feedback="
                                validationInvalidFeedback(
                                  errors.errors,
                                  'supply_order_items.' + ind + '.quantity'
                                )
                              "
                              class="mt-4"
                              prepend="Quantity"
                            >
                              <template #append>
                                <b-input-group-text>Packs</b-input-group-text>
                              </template>
                              <b-form-input
                                v-model="item.quantity"
                                :disabled="isSaving"
                                :state="
                                  validationState(
                                    errors.errors,
                                    'supply_order_items.' + ind + '.quantity'
                                  )
                                "
                              ></b-form-input>
                            </b-input-group>
                          </b-col>

                          <b-col cols="12" md="6">
                            <b-input-group
                              :invalid-feedback="
                                validationInvalidFeedback(
                                  errors.errors,
                                  'color'
                                )
                              "
                              class="mt-4"
                              prepend="Colour"
                            >
                              <b-form-input
                                v-model="item.color"
                                :disabled="isSaving"
                                :state="
                                  validationState(
                                    errors.errors,
                                    'supply_order_items.' + ind + '.color'
                                  )
                                "
                                placeholder="Product Color"
                              />
                            </b-input-group>
                          </b-col>

                          <b-col cols="12" md="6">
                            <b-input-group
                              :invalid-feedback="
                                validationInvalidFeedback(
                                  errors.errors,
                                  'design'
                                )
                              "
                              class="mt-4"
                              prepend="Design"
                            >
                              <b-form-input
                                v-model="item.design"
                                :disabled="isSaving"
                                :state="
                                  validationState(
                                    errors.errors,
                                    'supply_order_items.' + ind + '.design'
                                  )
                                "
                                placeholder="Product Design"
                              />
                            </b-input-group>
                          </b-col>

                          <b-col cols="12" md="6">
                            <b-input-group
                              :invalid-feedback="
                                validationInvalidFeedback(
                                  errors.errors,
                                  'supply_order_items.' + ind + '.status'
                                )
                              "
                              class="mt-4"
                              prepend="Status"
                            >
                              <b-form-select
                                v-model="item.status"
                                :options="options"
                                :state="
                                  validationState(
                                    errors.errors,
                                    'supply_order_items.' + ind + '.status'
                                  )
                                "
                              >
                              </b-form-select>
                            </b-input-group>
                          </b-col>

                          <b-col cols="12" md="6">
                            <b-input-group
                              :invalid-feedback="
                                validationInvalidFeedback(
                                  errors.errors,
                                  'supply_order_items.' + ind + '.date'
                                )
                              "
                              class="mt-4"
                              prepend="Date"
                            >
                              <b-form-datepicker
                                v-model="item.date"
                                :date-format-options="{
                                  year: 'numeric',
                                  month: 'numeric',
                                  day: 'numeric'
                                }"
                                :state="
                                  validationState(
                                    errors.errors,
                                    'supply_order_items.' + ind + '.date'
                                  )
                                "
                                :value="
                                  supplierOrder.supply_order_items[ind].date
                                "
                                reset-button
                                size="sm"
                              />
                            </b-input-group>
                          </b-col>
                        </b-row>

                        <b-row v-else></b-row>
                      </b-card>
                    </div>
                  </b-col>
                  <b-col class="mt-3" cols="12">
                    <b-button block variant="primary" @click.prevent="addItem"
                    >
                      <fa-icon icon="plus"/>
                      Add Item
                    </b-button
                    >
                  </b-col>
                </b-row>
              </b-card>
            </b-col>
            <b-col>
              <small class="text-danger">{{
                  validationInvalidFeedback(errors.errors, "supply_order_items")
                }}</small>
            </b-col>
          </b-row>
          <b-row class="mb-4">
            <b-col class="d-flex flex-row" cols="12" md="4" offset-md="8">
              <b-button
                block
                class="align-self-end mb-3"
                type="submit"
                variant="secondary"
              >
                <b-spinner v-if="isSaving" small></b-spinner
                >
                <span v-if="!isSaving">Save</span></b-button
              >
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-form>
  </b-container>
</template>

<script>
import validation from "../../mixins/validation";
import {mapActions, mapGetters} from "vuex";
import ProductSearch from "../../components/ProductSearch";
import momentMixin from "../../mixins/momentMixin";

export default {
  mixins: [validation, momentMixin],
  components: {
    ProductSearch
  },
  created() {
    if (this.$route.params.id) {
      this.fetch(this.$route.params.id);
    }
  },

  computed: {
    ...mapGetters("supply-orders", [
      "errors",
      "isLoading",
      "isSaving",
      "single"
    ])
  },
  data() {
    return {
      collapsed: {
        returns: !this.$route.params.id
      },

      openLot: null,

      supplierOrder: {
        name: new Date().toISOString().substr(0, 10),
        supply_order_items: [],
        date: new Date().toISOString().substr(0, 10),
        measurement_types_id: null
      },

      options: [
        {text: "Please select a status", value: null},
        {text: "Not Ordered", value: "Not Ordered"},
        {text: "Ordered", value: "Ordered"},
        {text: "Partial Goods In", value: "Partial Goods In"},
        {text: "Goods In", value: "Goods In"},
        {text: "Faulted", value: "Faulted"}
      ],

      deletedIds: [],
      editingProductIndex: null
    };
  },
  methods: {
    ...mapActions("supply-orders", ["fetch", "save"]),

    calculateSquareMeters(ind) {
      var totalQuantity =
        Math.round(
          (Number(this.supplierOrder.supply_order_items[ind].width) *
            Number(this.supplierOrder.supply_order_items[ind].length) +
            Number.EPSILON) *
          100
        ) / 100;
      this.supplierOrder.supply_order_items[ind].quantity = totalQuantity;

      return totalQuantity;
    },

    addLot(ind) {
      this.supplierOrder.supply_order_items[ind].lots.push({
        id: null,
        length: null
      });
    },

    submit() {
      let data = {
        id: this.$route.params.id,
        product_id: this.supplierOrder.product_id,
        name: this.supplierOrder.name,
        supply_order_items: this.supplierOrder.supply_order_items
      };

      this.save(data).then(response => {
        if ([200, 201].includes(response.status))
          this.$router.push({
            name: "dash.supply_orders.list",
            params: {
              notifications: [
                {
                  variant: "success",
                  title: `Supply Order #${response.data.id}`,
                  subtitle: `${response.data.name}`,
                  text: `You have ${
                    !this.$route.params.id
                      ? "created a new"
                      : "updated an existing"
                  } supply order.`,
                  timeout: 5000
                }
              ]
            }
          });
      });
    },
    rmItem(ind) {
      this.supplierOrder.supply_order_items.splice(ind, 1);
    },
    itemUpdatedDbItem(ind) {
      this.supplierOrder.supply_order_items[
        ind
        ].product_id = this.supplierOrder.supply_order_items[ind].product.id;
      this.supplierOrder.supply_order_items[
        ind
        ].name = this.supplierOrder.supply_order_items[ind].product.name;
      this.supplierOrder.supply_order_items[
        ind
        ].measurement_types_id = this.supplierOrder.supply_order_items[
        ind
        ].product.measurement_types_id;
    },
    itemUpdated(ind) {
      this.supplierOrder.supply_order_items[
        ind
        ].product_id = this.supplierOrder.supply_order_items[ind].product.id;
      this.supplierOrder.supply_order_items[
        ind
        ].product = this.supplierOrder.supply_order_items[ind].product.name;
      this.supplierOrder.supply_order_items[
        ind
        ].measurement_types_id = this.supplierOrder.supply_order_items[
        ind
        ].product.measurement_types_id;
    },
    addItem() {
      this.supplierOrder.supply_order_items.push({
        id: null,
        product_id: null,
        product_variation_id: null,
        product: {},
        quantity: null,
        width: null,
        length: null,
        date: null,
        lots: [],
        status: null,
        name: null,
        measurement_types_id: null
      });
    },

    productVariationUpdated(ind) {
      var productVariation = this.supplierOrder.supply_order_items[
        ind
        ].product.variations.find(variation => {
        return (
          this.supplierOrder.supply_order_items[ind].product_variation_id ==
          variation.id
        );
      });

      if (productVariation) {
        this.supplierOrder.supply_order_items[ind].name = productVariation.name;
        this.supplierOrder.supply_order_items[ind].width =
          productVariation.width;
      }
    }
  },

  watch: {
    single() {
      this.supplierOrder = this.single;
    }
  }
};
</script>

<style></style>
